import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import GenericTable from "../../components/GenericTable";

import {SEARCH_CUSTOMERS_REQUEST_DATA} from "../../store/actions/customer/search-customers-actions";
import AutoComplete from "../../components/AutoComplete";
import {useTranslation} from "react-i18next";
import NewCustomerModal from "../../components/NewCustomerModal";


const Customers = () => {

    const [currentPage, setCurrentPage] = useState(0);

    const pageSize = 10;

    const {t} = useTranslation();

    const dispatch = useDispatch();

    const {data , isLoading, error} = useSelector((state) => state.searchCustomersData);

    const [customerType, setCustomerType] = useState('COMPANY');

    const [searchTerm, setSearchTerm] = useState('');

    const [isModalOpen, setModalOpen] = useState(false);

    const toIgnoreHeadersForSearchTable = [
        'workAddress.streetName',
        'workAddress.streetNumber',
        'workAddress.city',
        'workAddress.country',
        'workAddress.postalCode',
        'workAddress.state',
        'type',
        'id'
    ]
    const prioritySearchTableFields = ['name', 'taxId'];

    function handleRadioButtonClick(e) {
        let type = e.target.value;
        setCustomerType(type)
        setCurrentPage(0)
        dispatch({
            type: SEARCH_CUSTOMERS_REQUEST_DATA,
            payload: {
                params: {
                    searchTerm: searchTerm,
                    customerType: type,
                    page: currentPage,
                    size: pageSize
                }
            }
        })
    }

    function handleOnChange(value) {
        setSearchTerm(value);
        dispatch({
            type: SEARCH_CUSTOMERS_REQUEST_DATA,
            payload: {
                params: {
                    searchTerm: value,
                    customerType: customerType,
                    page: currentPage,
                    size: pageSize
                }
            }
        })
    }

    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < data.totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    useEffect(() => {
        dispatch({
            type: SEARCH_CUSTOMERS_REQUEST_DATA, payload: {
                params: {
                    customerType: customerType,
                    page: currentPage,
                    size: pageSize,
                }
            }
        })
    }, [currentPage])

    return (
        <div className="container mx-auto customers-container">
            <br/>
            <div className="flex gap-10">
                <div className="inline-flex items-center">
                    <label className="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor="company">
                        <input name="type"
                               type="radio"
                               className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-gray-900 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                               id="company"
                               value={'COMPANY'}
                               onChange={handleRadioButtonClick}
                               checked={customerType === 'COMPANY'}
                        />
                        <span
                            className="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 16 16"
                                 fill="currentColor">
                                    <circle data-name="ellipse" cx="8" cy="8" r="8"/>
                            </svg>
                     </span>
                    </label>
                    <label className="mt-px font-light text-gray-700 cursor-pointer select-none" htmlFor="company">
                        {t(`customer.company`)}
                    </label>
                </div>
                <div className="inline-flex items-center">
                    <label className="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor="individual">
                        <input name="type" type="radio"
                               className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-gray-900 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                               id="individual"
                               onChange={handleRadioButtonClick}
                               checked={customerType === 'INDIVIDUAL'}
                               value={'INDIVIDUAL'}

                        />
                        <span
                            className="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                           <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 16 16"
                                fill="currentColor">
                             <circle data-name="ellipse" cx="8" cy="8" r="8"/>
                            </svg>
                        </span>
                    </label>
                    <label className="mt-px font-light text-gray-700 cursor-pointer select-none" htmlFor="individual">
                        {t(`customer.individual`)}
                    </label>
                </div>
                <div className="inline-flex items-center">
                    <button onClick={() => setModalOpen(true)}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            style={{backgroundColor: '#34495e'}}>
                        {t('customer.createCustomer')}
                    </button>
                </div>
            </div>
            <br/>
            <AutoComplete enableSuggestions={false} onChange={handleOnChange}/>
            <br/>
            { console.log(data)}
            {
                data.value ?
                    <GenericTable
                        data={data.value}
                        columnsToIgnore={toIgnoreHeadersForSearchTable}
                        priorityColumns={prioritySearchTableFields}
                        translationKeyPrefix={'customer'}/>
                    : null
            }
            <br/>
            {
                data.totalPages > 1 ?
                <div className="flex justify-between items-center mt-4">
                    <button onClick={handlePreviousPage} disabled={currentPage <= 0}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            style={{backgroundColor: '#34495e'}}>
                        {t('pagination.previous')}
                    </button>
                    <span>{t('pagination.page')} {currentPage + 1} {t('pagination.of')} {data.totalPages}</span>
                    <button onClick={handleNextPage} disabled={currentPage >= data.totalPages - 1}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            style={{backgroundColor: '#34495e'}}>
                        {t('pagination.next')}
                    </button>
                </div> : null
            }
            <br/>
            <br/>
            <NewCustomerModal isOpen={isModalOpen} onClose={() => setModalOpen(false)}/>
        </div>
    )
}


export default Customers;
