import { call, put, takeLatest } from 'redux-saga/effects';
import {
    CREATE_PRODUCT_SUBMIT_FORM, formSubmittedSuccess, formSubmittedFail
} from "../../actions/product/create-product-actions";
import {createProduct} from '../../../services/product/product-service'

function* handleFormSubmit(action) {
    try {
        const response = yield call(createProduct, action.payload);
        yield put(formSubmittedSuccess(response));
    } catch (e) {
        yield put(formSubmittedFail(e.message));
    }
}

export function* watchProductCreateFormSubmit() {
    yield takeLatest(CREATE_PRODUCT_SUBMIT_FORM, handleFormSubmit);
}
