import { call, put, takeLatest } from 'redux-saga/effects';
import { actionTypes, formSubmittedSuccess, formSubmittedFail } from '../../actions/customer/create-customer-actions';
import {createCustomer} from '../../../services/customer/customer-service'

function* handleFormSubmit(action) {
    try {
        const response = yield call(createCustomer, action.payload);
        yield put(formSubmittedSuccess(response));
    } catch (e) {
        yield put(formSubmittedFail(e.message));
    }
}

export function* watchCustomerCreateFormSubmit() {
    yield takeLatest(actionTypes.SUBMIT_FORM, handleFormSubmit);
}
