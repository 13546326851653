import React from 'react';
import './Sidebar.scss';
import {useDispatch} from "react-redux";
import logo from "../assets/images/logo-no-text.png"
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Sidebar = ({onClick}) => {

    const { t, i18n } = useTranslation();

    const dispatch = useDispatch()

    function handleLogoutClick() {
        dispatch({type: 'LOGOUT'})
    }

    function handleOnClick(e) {
        onClick(e.currentTarget.getAttribute('translation-key'))

    }

    function handleChangeLanguage(e) {
        let lng = e.currentTarget.getAttribute('data-language');
        i18n.changeLanguage(lng);
    }

    return (
        <div className="sidebar">
            <div className="logo">
                <img src={logo} alt="Company Logo"/>
            </div>
            <nav className="menu">
                <ul>
                    <li><Link to="/dashboard" onClick={handleOnClick} translation-key="sideBar.dashboard">{t(`sideBar.dashboard`)}</Link></li>
                    <li><Link to="/messages" onClick={handleOnClick} translation-key="sideBar.messages">{t(`sideBar.messages`)}</Link></li>
                    <li><Link to="/messages" onClick={handleOnClick} translation-key="sideBar.orders">{t(`sideBar.orders`)}</Link></li>
                    <li><Link to="/customers" onClick={handleOnClick} translation-key="sideBar.customers">{t(`sideBar.customers`)}</Link></li>
                    <li><Link to="/product" onClick={handleOnClick} translation-key="sideBar.products">{t(`sideBar.products`)}</Link></li>
                    <li><Link to="/news" onClick={handleOnClick} translation-key="sideBar.news">{t(`sideBar.news`)}</Link></li>
                    <li><Link to="#" onClick={handleLogoutClick}>{t(`sideBar.logout`)}</Link></li>
                    <br/>
                    <li data-language="rs" onClick={handleChangeLanguage}>
                        RS
                    </li>
                    <li data-language="en" onClick={handleChangeLanguage}>
                        EN
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Sidebar;
