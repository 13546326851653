import React, {useEffect, useState} from 'react';
import Header from "../../components/Header";
import {Outlet, useNavigate, useLocation} from 'react-router-dom';
import Sidebar from "../../components/Sidebar";


const AppContainer = () => {

    const [currentContainer, setCurrentContainer] = useState('sideBar.dashboard')
    const navigate = useNavigate();
    const location = useLocation();


    function handleSideBarElementOnClick(currentView) {
        setCurrentContainer(currentView)
    }

    useEffect(() => {
        if (location.pathname === '' || location.pathname === '/') {
            navigate('/dashboard')
        }
    }, [])

    return (
        <div className="flex-row">
            <div className="flex-15">
                <Sidebar onClick={handleSideBarElementOnClick}/>
            </div>
            <div className="flex-80">
                <Header currentView={currentContainer}/>
                <Outlet/>
            </div>
        </div>
    );
};

export default AppContainer;
