export const CREATE_PRODUCT_UPDATE_FORM = "UPDATE_FORM";
export const CREATE_PRODUCT_SUBMIT_FORM = "CREATE_PRODUCT_SUBMIT_FORM";
export const CREATE_PRODUCT_FORM_SUBMITTED_SUCCESS = "FORM_SUBMITTED_SUCCESS";
export const CREATE_PRODUCT_FORM_SUBMITTED_FAIL = "FORM_SUBMITTED_FAIL";


export const updateForm = (formData) => ({
    type: CREATE_PRODUCT_UPDATE_FORM,
    payload: formData,
});

export const submitForm = (formData) => ({
    type: CREATE_PRODUCT_SUBMIT_FORM,
    payload: formData,
});

export const formSubmittedSuccess = (response) => ({
    type: CREATE_PRODUCT_FORM_SUBMITTED_SUCCESS,
    payload: response,
});

export const formSubmittedFail = (error) => ({
    type: CREATE_PRODUCT_FORM_SUBMITTED_FAIL,
    payload: error,
});
