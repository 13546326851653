export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR";
export const LOGOUT = "LOGOUT";
export const INIT_AUTH= "INIT_AUTH";
export const INIT_AUTH_COMPLETE= "INIT_AUTH_COMPLETE";
export const INIT_AUTH_FAILED= "INIT_AUTH_FAILED";


