import React, { useState } from 'react';
import {createNews} from '../services/news/news-service'
// Mock data for SupportedClient
const supportedClients = [
    { id: 'DEFAULT', name: 'Default' },
    { id: 'SONY', name: 'Sony' },
    { id: 'ARRI', name: 'Arri' },
    { id: 'RED', name: 'Red' },
];

function NewsCreateForm() {
    const [data, setData] = useState({
        heading: '',
        text: '',
        url: '',
        logo: null,
        mainImage: null,
        company: 'DEFAULT',
    });

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
            setData({ ...data, [name]: files[0] });
        } else {
            setData({ ...data, [name]: value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission logic here

        const formData = new FormData();
        formData.append('heading', data.heading);
        formData.append('text', data.text);
        formData.append('url', data.url || ''); // Assuming url is optional
        formData.append('logo', data.logo || null);
        formData.append('mainImage', data.mainImage);
        formData.append('company', data.company);
        await createNews(formData).then(value => {
            console.log(value)
        }).catch(reason => {
            console.log(reason)
        })
    };

    return (
        <form onSubmit={handleSubmit} className="max-w-lg mx-auto bg-white p-8 mt-10 rounded-lg shadow">
            <div className="mb-6">
                <label htmlFor="heading" className="block mb-2 text-sm font-medium text-gray-700">Heading</label>
                <input type="text" id="heading" name="heading" value={data.heading} onChange={handleChange} required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>
            </div>
            <div className="mb-6">
                <label htmlFor="text" className="block mb-2 text-sm font-medium text-gray-700">Text</label>
                <textarea id="text" name="text" value={data.text} onChange={handleChange} required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 h-32"/>
            </div>
            <div className="mb-6">
                <label htmlFor="url" className="block mb-2 text-sm font-medium text-gray-700">URL</label>
                <input type="text" id="url" name="url" value={data.url} onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"/>
            </div>
            <div className="mb-6">
                <label htmlFor="logo" className="block mb-2 text-sm font-medium text-gray-700">Logo</label>
                <input type="file" id="logo" name="logo" onChange={handleChange} className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer"/>
            </div>
            <div className="mb-6">
                <label htmlFor="mainImage" className="block mb-2 text-sm font-medium text-gray-700">Main Image</label>
                <input type="file" id="mainImage" name="mainImage" onChange={handleChange} required className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer"/>
            </div>
            <div className="mb-6">
                <label htmlFor="company" className="block mb-2 text-sm font-medium text-gray-700">Company</label>
                <select id="company" name="company" value={data.company} onChange={handleChange} required className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                    <option value="">Please Select</option>
                    {supportedClients.map((client) => (
                        <option key={client.id} value={client.id}>{client.name}</option>
                    ))}
                </select>
            </div>
            <button type="submit" className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Submit</button>
        </form>
    );
}

export default NewsCreateForm
