import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from './assets/i18n/en/translation.json'
import rsTranslation from './assets/i18n/rs/translation.json'

const resources = {
    en: {
        translation: enTranslation
    },
    rs: {
        translation: rsTranslation
    }
}


i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: "en",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
