import {call, put, takeLatest, all, delay} from "redux-saga/effects";
import keycloak from "../auth-provider";
import {
    INIT_AUTH,
    LOGIN_ERROR,
    LOGIN_SUCCESS,
    LOGOUT,
    REFRESH_TOKEN,
    REFRESH_TOKEN_ERROR,
    REFRESH_TOKEN_SUCCESS
} from "../actions/auth-actions";

function* initializeAuthentication() {
    try {
        const authenticated = keycloak.authenticated;
        if (authenticated) {
            yield put({ type: LOGIN_SUCCESS, payload: keycloak.token });
        } else {
            yield put({ type: LOGIN_ERROR });
        }
    } catch (error) {
        yield put({ type: LOGIN_ERROR, error });
    }
}

function* refreshToken() {
    while (true) {
        yield delay(keycloak.tokenParsed.exp * 1000 - Date.now() - 10 * 1000);
        try {
            yield call(() => keycloak.updateToken(30));
            yield put({type: REFRESH_TOKEN_SUCCESS, payload: keycloak.token});
        } catch (error) {
            yield put({type: REFRESH_TOKEN_ERROR, error});
        }
    }
}

function* logout() {
    yield call([keycloak, keycloak.logout]);
    yield put({type: LOGOUT});
}




export function* authSaga() {
    yield all([
        takeLatest(INIT_AUTH, initializeAuthentication),
        takeLatest(REFRESH_TOKEN, refreshToken),
        takeLatest(LOGOUT, logout)])

}
