import './App.scss';
import React, {Suspense} from 'react';

import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";

import ProtectedRoute from './routes/ProtectedRoute';
import LoadingSpinner from "./components/LoadingSpinner";
import AppContainer from "./containers/app-container/AppContainer";
import PublicRoute from "./routes/PublicRoute";
import LoginPage from "./containers/auth/LoginPage";
import NotFoundContainer from "./containers/not-found/NotFound";
import Info from "./containers/info/Info";
import Dashboard from "./containers/dashboard/Dashboard";
import Customers from "./containers/customers/Customers";
import Product from "./containers/product/Product";
import News from "./containers/news/News";


const App = () => {

    return (
        <BrowserRouter>
            <Suspense fallback={<LoadingSpinner/>}>
                <Routes>
                    <Route element={<ProtectedRoute/>}>
                        <Route path="/" element={<AppContainer/>}>
                            <Route index path="/dashboard" element={<Dashboard/>}/>
                            <Route path="/messages" element={<Info/>}/>
                            <Route path="/customers" element={<Customers/>}/>
                            <Route path="/product" element={<Product/>}/>
                            <Route path="/news" element={<News/>}/>
                        </Route>
                    </Route>
                    <Route element={<PublicRoute/>}>
                        <Route path="/login"  element={<LoginPage/>}/>
                    </Route>
                    <Route path="*" element={<NotFoundContainer/>}/>
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
};


export default App;
