import Keycloak from "keycloak-js";
import {INIT_AUTH, INIT_AUTH_COMPLETE, INIT_AUTH_FAILED, REFRESH_TOKEN} from "./actions/auth-actions";
import store from "./index";

const keycloakConfig = {
    url: "http://kc.visuals.rs:8089/",
    realm: "Visual_Impact",
    clientId: "visuals-admin-tool",
};


const keycloak = new Keycloak(keycloakConfig);

keycloak.init({onLoad: 'login-required'})
    .then(auth => {
        if (auth) {
            store.dispatch({type: INIT_AUTH})
            store.dispatch({type: INIT_AUTH_COMPLETE})
            store.dispatch({type: REFRESH_TOKEN})

        } else {
            console.error("Something went wrong with KC auth.")
            store.dispatch({type: INIT_AUTH_FAILED})
        }
    }).catch(reason => {
    console.error(reason)
    store.dispatch({type: INIT_AUTH_FAILED})
})

export default keycloak;
