import { takeEvery, put, call } from 'redux-saga/effects';
import { REQUEST_CURRENCY_DATA, receiveData, requestFailed } from '../../actions/currency/fetch-currency-data-actions';
import {fetchCurrencyDataByDate} from '../../../services/currency/currency-service'

function* fetchData(action) {
    try {
        const response = yield call(
            fetchCurrencyDataByDate, // Function reference
            action.payload.date
        );
        yield put(receiveData(response.data));
    } catch (error) {
        yield put(requestFailed(error.toString()));
    }
}

export function* watchFetchCurrencyData() {
    yield takeEvery(REQUEST_CURRENCY_DATA, fetchData);
}
