import React from 'react';
import {useTranslation} from "react-i18next";

const GenericTable = ({data, columnsToIgnore, translationKeyPrefix}) => {
    const { t } = useTranslation();

    const flattenObjectForKeys = (obj, parentKey = '', keys = []) => {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const propName = parentKey ? `${parentKey}.${key}` : key;
                if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
                    flattenObjectForKeys(obj[key], propName, keys);
                } else {
                    keys.push(propName);
                }
            }
        }
        return keys;
    };

    const flattenObject = (obj, parentKey = '') => {
        let flattened = {};

        for (const currKey in obj) {
            if(obj.hasOwnProperty(currKey)) {
                const propName = parentKey ? `${parentKey}.${currKey}` : currKey;
                if (typeof obj[currKey] === 'object' &&
                    obj[currKey] !== null &&
                    !Array.isArray(obj[currKey])) {
                    Object.assign(flattened, flattenObject(obj[currKey], propName));
                } else {
                    flattened[propName] = obj[currKey];
                }
            }
        }
        return flattened;
    };


    const renderTableHeader = (data) => {
        const headerKeys = flattenObjectForKeys(data[0])
            .filter(key => !columnsToIgnore.includes(key));

        return headerKeys.map(key => (
            <th
                key={key}
                className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left"
            >
                {t(`${translationKeyPrefix}.${key}`)}
            </th>
        ));
    };

    const renderTableRow = (dataItem, flattenedKeys) => {
        const flattenedData = flattenObject(dataItem);

        return (
            <tr key={dataItem.id || Math.random()}>
                {flattenedKeys.map(key => (
                    <td
                        key={key}
                        className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400"
                    >
                        {flattenedData[key]}
                    </td>
                ))}
            </tr>
        );
    };

// In your render or return method
    const flattenedKeys = data && data.length > 0 ? flattenObjectForKeys(data[0]).filter(key => !columnsToIgnore.includes(key)) : [];

    return (
        <table className="border-collapse table-auto w-full text-sm">
            <thead>
            <tr>
                {data && data.length > 0 && renderTableHeader(data)}
            </tr>
            </thead>
            <tbody className="bg-white dark:bg-slate-800">
            {data?.map(dataItem => renderTableRow(dataItem, flattenedKeys))}
            </tbody>
        </table>
    )
}

export default GenericTable;
