export const actionTypes = {
    UPDATE_FORM: 'UPDATE_FORM',
    SUBMIT_FORM: 'SUBMIT_FORM',
    FORM_SUBMITTED_SUCCESS: 'FORM_SUBMITTED_SUCCESS',
    FORM_SUBMITTED_FAIL: 'FORM_SUBMITTED_FAIL',
};

export const updateForm = (formData) => ({
    type: actionTypes.UPDATE_FORM,
    payload: formData,
});

export const submitForm = (formData) => ({
    type: actionTypes.SUBMIT_FORM,
    payload: formData,
});

export const formSubmittedSuccess = (response) => ({
    type: actionTypes.FORM_SUBMITTED_SUCCESS,
    payload: response,
});

export const formSubmittedFail = (error) => ({
    type: actionTypes.FORM_SUBMITTED_FAIL,
    payload: error,
});
