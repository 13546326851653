import { all } from 'redux-saga/effects';
import { watchFetchCurrencyData } from './currency/fetch-currency-data-saga';
import {watchSearchForCustomers} from "./customer/search-customers-saga";
import { authSaga } from './auth-saga';
import {watchCustomerCreateFormSubmit} from "./customer/create-customer-saga";
import {watchSearchForProducts} from "./product/search-products-saga";
import {watchProductCreateFormSubmit} from "./product/create-product-saga";


export default function* rootSaga() {
    yield all([
        authSaga(),
        watchFetchCurrencyData(),
        watchSearchForCustomers(),
        watchCustomerCreateFormSubmit(),
        watchSearchForProducts(),
        watchProductCreateFormSubmit()
    ]);
}
