import React from "react";
import './News.scss'
import NewsCreateForm from "../../components/NewsCreateForm";



const News = () => {
    return (
        <div className="container mx-auto customers-container">
        <h1>Create new news</h1>
        <br/>
        <NewsCreateForm/>
        </div>
    )
}

export default News;
