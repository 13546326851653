import React from "react";
import "./LoadingSpinner.scss";

export default function LoadingSpinner(logo,logoAlt,width) {
    return (
        <div className="spinner-container">
            <div className="loading-spinner">
                <img alt = {logoAlt} src={logo} className="load-spinner-img" width={width}/>
            </div>
        </div>
    );
}
