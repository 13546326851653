import {
    CREATE_PRODUCT_FORM_SUBMITTED_FAIL,
    CREATE_PRODUCT_FORM_SUBMITTED_SUCCESS,
    CREATE_PRODUCT_SUBMIT_FORM,
    CREATE_PRODUCT_UPDATE_FORM
} from "../../actions/product/create-product-actions";
import {ProductTypes, SupportedClients} from "../../../containers/product/Product";


// Adjusting initialFormData to use default values for enums
const initialFormData = {
    name: '',
    code: '',
    description: '',
    price: 0.00,
    type: ProductTypes.DEFAULT, // Default to one of the enum values, or null if selection is mandatory
    company: SupportedClients.DEFAULT, // Similarly, default or null based on requirement
};


const initialState = {
    formData: initialFormData,
    loading: false,
    error: null,
    response: null,
};

const createProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_PRODUCT_UPDATE_FORM:
            return {
                ...state,
                formData: { ...state.formData, ...action.payload },
            };
        case CREATE_PRODUCT_SUBMIT_FORM:
            return {
                ...state,
                loading: true,
            };
        case CREATE_PRODUCT_FORM_SUBMITTED_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
            };
        case CREATE_PRODUCT_FORM_SUBMITTED_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default createProductReducer;
