import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {submitForm, updateForm} from "../store/actions/customer/create-customer-actions";

const CUSTOMER_TYPES = {
    INDIVIDUAL: 'INDIVIDUAL',
    COMPANY: 'COMPANY',
};
const initialFormData = {
    type: CUSTOMER_TYPES.INDIVIDUAL, // Default to individual
    address: {
        streetName: '',
        streetNumber: '',
        city: '',
        country: '',
        postalCode: '',
        state: '',
    },
    workAddress: {
        streetName: '',
        streetNumber: '',
        city: '',
        country: '',
        postalCode: '',
        state: '',
    },
    contactDetails: {
        email: '',
        phoneNumber: '',
        mobilePhoneNumber: '',
    },
    // Specific to COMPANY
    name: '',
    taxId: '',
    // Specific to INDIVIDUAL
    firstName: '',
    lastName: '',
};

function NewCustomerModal({isOpen, onClose}) {

    const {t} = useTranslation();

    const [customerType, setCustomerType] = useState(CUSTOMER_TYPES.INDIVIDUAL);

    const dispatch = useDispatch();
    // Accessing Redux state
    const { formData, loading, error, response } = useSelector(state => state.createCustomer);


    useEffect(() => {
        if (response) {
            // Handle successful form submission
            console.log(response);
            onClose();
        }

        if (error) {
            // Handle form submission error
            console.error(error);
        }
    }, [response, error, onClose]);



    const handleChange = (e) => {
        const { name, value } = e.target;
        const nameParts = name.split('.');

        // Prepare new formData for dispatching
        let newFormData = { ...formData };

        if (nameParts.length === 1) {
            newFormData = { ...newFormData, [name]: value };
        } else {
            newFormData = {
                ...newFormData,
                [nameParts[0]]: {
                    ...newFormData[nameParts[0]],
                    [nameParts[1]]: value,
                },
            };
        }

        dispatch(updateForm(newFormData));
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(submitForm(formData));
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg">Create Customer</h3>
                    <button onClick={onClose} className="text-gray-400 hover:bg-gray-200 p-2 rounded">
                        X
                    </button>
                </div>
                <select
                    name="type"
                    value={customerType}
                    onChange={(e) => {
                        handleChange(e)
                        setCustomerType(e.target.value)
                    }}
                    className="mb-4 p-2 border rounded"
                >
                    <option value={CUSTOMER_TYPES.INDIVIDUAL}>{t('customer.individual')}</option>
                    <option value={CUSTOMER_TYPES.COMPANY}>{t('customer.company')}</option>
                </select>

                <form onSubmit={handleSubmit}>
                    {/* Conditional rendering based on customer type */}
                    {customerType === CUSTOMER_TYPES.INDIVIDUAL ? (
                        <>
                            <input
                                name="firstName"
                                placeholder={t('customer.firstName')}
                                value={formData.firstName}
                                onChange={handleChange}
                                className="block w-full mb-2 p-2 border rounded"
                            />
                            <input
                                name="lastName"
                                placeholder={t('customer.lastName')}
                                value={formData.lastName}
                                onChange={handleChange}
                                className="block w-full mb-2 p-2 border rounded"
                            />
                        </>
                    ) : (
                        <>
                            <input
                                name="name"
                                placeholder={t('customer.name')}
                                value={formData.name}
                                onChange={handleChange}
                                className="block w-full mb-2 p-2 border rounded"
                            />
                            <input
                                name="taxId"
                                placeholder={t('customer.taxId')}
                                value={formData.taxId}
                                onChange={handleChange}
                                className="block w-full mb-2 p-2 border rounded"
                            />
                        </>
                    )}
                    {/* Common fields */}
                    <br/>
                    <div className="space-y-2">
                        <input type="email" name="contactDetails.email" placeholder={t('customer.contactDetails.email')}
                               value={formData.contactDetails.email} onChange={handleChange}
                               className="block w-full mb-2 p-2 border rounded"/>
                        <input name="contactDetails.phoneNumber" placeholder={t('customer.contactDetails.phoneNumber')}
                               value={formData.contactDetails.phoneNumber} onChange={handleChange}
                               className="block w-full mb-2 p-2 border rounded"/>
                        <input name="contactDetails.mobilePhoneNumber" placeholder={t('customer.contactDetails.mobilePhoneNumber')}
                               value={formData.contactDetails.mobilePhoneNumber} onChange={handleChange}
                               className="block w-full mb-2 p-2 border rounded"/>
                    </div>
                    <br/>
                    <div className="space-y-2">
                        <input name="address.streetName"  placeholder={t('customer.address.streetName')} value={formData.address.streetName}
                               onChange={handleChange} className="block w-full mb-2 p-2 border rounded"/>
                        <input name="address.streetNumber" placeholder={t('customer.address.streetNumber')}
                               value={formData.address.streetNumber} onChange={handleChange}
                               className="block w-full mb-2 p-2 border rounded"/>
                        <input name="address.city" placeholder={t('customer.address.city')} value={formData.address.city}
                               onChange={handleChange} className="block w-full mb-2 p-2 border rounded"/>
                        <input name="address.country" placeholder={t('customer.address.country')}  value={formData.address.country}
                               onChange={handleChange} className="block w-full mb-2 p-2 border rounded"/>
                        <input name="address.postalCode" placeholder={t('customer.address.postalCode')} value={formData.address.postalCode}
                               onChange={handleChange} className="block w-full mb-2 p-2 border rounded"/>
                        <input name="address.state" placeholder={t('customer.address.state')} value={formData.address.state}
                               onChange={handleChange} className="block w-full mb-2 p-2 border rounded"/>
                    </div>
                    <br/>
                    <div className="space-y-2">
                        <input name="workAddress.streetName" placeholder={t('customer.workAddress.streetName')}
                               value={formData.workAddress.streetName} onChange={handleChange}
                               className="block w-full mb-2 p-2 border rounded"/>
                        <input name="workAddress.streetNumber" placeholder={t('customer.workAddress.streetNumber')}
                               value={formData.workAddress.streetNumber} onChange={handleChange}
                               className="block w-full mb-2 p-2 border rounded"/>
                        <input name="workAddress.city"  placeholder={t('customer.workAddress.city')} value={formData.workAddress.city}
                               onChange={handleChange} className="block w-full mb-2 p-2 border rounded"/>
                        <input name="workAddress.country" placeholder={t('customer.workAddress.country')}
                               value={formData.workAddress.country} onChange={handleChange}
                               className="block w-full mb-2 p-2 border rounded"/>
                        <input name="workAddress.postalCode" placeholder={t('customer.workAddress.postalCode')}
                               value={formData.workAddress.postalCode} onChange={handleChange}
                               className="block w-full mb-2 p-2 border rounded"/>
                        <input name="workAddress.state" placeholder={t('customer.workAddress.state')}
                               value={formData.workAddress.state} onChange={handleChange}
                               className="block w-full mb-2 p-2 border rounded"/>
                    </div>
                    <br/>
                    <button type="submit"
                            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
                        Submit
                    </button>
                </form>
            </div>
        </div>
    )
}

export default NewCustomerModal;
