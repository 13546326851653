import {
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    REFRESH_TOKEN_ERROR,
    REFRESH_TOKEN_SUCCESS,
    LOGOUT,
    INIT_AUTH, INIT_AUTH_COMPLETE, INIT_AUTH_FAILED
} from '../actions/auth-actions';

const initialState = {
    token: null,
    error: null,
    isAuthenticated: false,
    isInitializing: true
};


const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case INIT_AUTH_COMPLETE:
            return { ...state, isInitializing: false };
        case INIT_AUTH_FAILED:
            return { ...state, isInitializing: true };
        case LOGIN_SUCCESS:
            return { ...state, token: action.payload, isAuthenticated: true };
        case LOGIN_ERROR:
            return { ...state, error: action.error, isAuthenticated: false };
        case REFRESH_TOKEN_SUCCESS:
            return { ...state, token: action.payload };
        case REFRESH_TOKEN_ERROR:
            return { ...state, error: action.error };
        case LOGOUT:
            return { ...state, token: null, isAuthenticated: false };
        default:
            return state;
    }
};

export default authReducer;
