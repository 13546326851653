import React from 'react';
import './Header.scss'
import {useTranslation} from "react-i18next";

const Header = ({currentView}) => {
    const { t } = useTranslation();

    return (
        <nav className="header">
            <ul>
                <li><div className="active">{t(currentView)}</div></li>
            </ul>
        </nav>
    );
};

export default Header;
