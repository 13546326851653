import BaseService from "../base";
import keycloak from "../../store/auth-provider";


const apiService = new BaseService('http://api.visuals.rs:8081/v1/currency', {});

export async function fetchCurrencyDataByDate(date) {
    if (keycloak.authenticated) {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${keycloak.token}`,
            'Accept': 'application/json'
        }
        return await apiService.get('', {date: date}, {headers: headers})
            .then(result => {
                return result.data
            })
            .catch(reason => {
                console.error(reason)
                throw reason
            })
    }
    throw new Error('You are not authenticated')
}

