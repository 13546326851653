import React from 'react';
import './CurrencyTable.scss'
import { useTranslation } from 'react-i18next';

const CurrencyTable = ({data, client}) => {
    const { t } = useTranslation();

    return (
        (client && data && data.length > 0) ?
            <table className="border-collapse table-auto w-full text-sm">
                <thead>
                <tr>
                    {Object.keys(data[0])
                        .filter(
                            key=> key !== 'toCurrency' && key !== 'client'
                        )
                        .map(key => (
                        <th key={key} className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">{t(`currency.${key}`)} </th>
                    ))}
                </tr>
                </thead>
                <tbody className="bg-white dark:bg-slate-800">
                {data?.filter(currency => client === currency['client'])
                    .sort((a, b) => a['fromCurrency'].toLowerCase().localeCompare(b['fromCurrency'].toLowerCase()))
                    .map((currency, index) => (
                    <tr key={index}>
                        {Object.keys(currency).filter(
                            key=> key !== 'toCurrency' && key !== 'client'
                        ).map(key => (
                            <td key={key}  className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">{currency[key]}</td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
            : null
    );
}


export default CurrencyTable;
