import { actionTypes } from '../../actions/customer/create-customer-actions';


const CUSTOMER_TYPES = {
    INDIVIDUAL: 'INDIVIDUAL',
    COMPANY: 'COMPANY',
};
const initialFormData = {
    type: CUSTOMER_TYPES.INDIVIDUAL, // Default to individual
    address: {
        streetName: '',
        streetNumber: '',
        city: '',
        country: '',
        postalCode: '',
        state: '',
    },
    workAddress: {
        streetName: '',
        streetNumber: '',
        city: '',
        country: '',
        postalCode: '',
        state: '',
    },
    contactDetails: {
        email: '',
        phoneNumber: '',
        mobilePhoneNumber: '',
    },
    // Specific to COMPANY
    name: '',
    taxId: '',
    // Specific to INDIVIDUAL
    firstName: '',
    lastName: '',
};



const initialState = {
    formData: initialFormData,
    loading: false,
    error: null,
    response: null,
};

const createCustomerReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_FORM:
            return {
                ...state,
                formData: { ...state.formData, ...action.payload },
            };
        case actionTypes.SUBMIT_FORM:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.FORM_SUBMITTED_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
            };
        case actionTypes.FORM_SUBMITTED_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default createCustomerReducer;
