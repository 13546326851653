import React from 'react';

const LoginPage = () => {
    return (
        <div>
            Redirecting to Keycloak for authentication...
        </div>
    );
};

export default LoginPage;
