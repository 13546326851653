import React, {useEffect, useState} from "react";
import './Product.scss'
import {useDispatch, useSelector} from "react-redux";
import {SEARCH_PRODUCT_REQUEST_DATA} from "../../store/actions/product/search-product-actions";
import AutoComplete from "../../components/AutoComplete";
import GenericTable from "../../components/GenericTable";
import {useTranslation} from "react-i18next";
import NewProductModal from "../../components/NewProductModal";


export const ProductTypes = {
    VIDEO: 'VIDEO', // Assuming possible enum values, replace with actual ones
    AUDIO: 'AUDIO',
    LIGHTING: 'LIGHTING',
    DEFAULT: 'DEFAULT'
    // Add other types as necessary
};

export const SupportedClients = {
    DEFAULT: 'DEFAULT',
    SONY: 'SONY',
    ARRI: 'ARRI',
    RED: 'RED',
    // Add other supported clients as necessary
};

const Product = () => {

    const {t} = useTranslation();

    const dispatch = useDispatch();

    const pageSize = 10;

    const { data, isLoading, error } = useSelector(state => state.searchProductsData);

    const [searchTerm, setSearchTerm] = useState('');

    const [isModalOpen, setModalOpen] = useState(false);

    const [currentPage, setCurrentPage] = useState(0);


    const prioritySearchTableFields = ['name', 'code'];

    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < data.totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };


    function handleOnChange(value) {
        setSearchTerm(value);
        dispatch({
            type: SEARCH_PRODUCT_REQUEST_DATA,
            payload: {params: {
                searchTerm: value,
                page: currentPage,
                size: pageSize
            }}
        })
    }


    useEffect(() => {
        dispatch({type: SEARCH_PRODUCT_REQUEST_DATA, payload: {params: {
            searchTerm: searchTerm,
            page: currentPage,
            size: pageSize
        }}})
    }, [currentPage])

    return (
        <div className="container mx-auto customers-container">
            <br/>
            <div className="flex gap-10">
                <div className="inline-flex items-center">
                    <button   onClick={() => setModalOpen(true)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" style={{backgroundColor: '#34495e'}}>
                        {t('product.addProduct')}
                    </button>
                </div>
            </div>
            <br/>
            <AutoComplete enableSuggestions={false} onChange={handleOnChange}/>
            <br/>
            {
                data.value ?
                    <GenericTable
                        data={data.value}
                        columnsToIgnore={['id']}
                        priorityColumns={prioritySearchTableFields}
                        translationKeyPrefix={'product'}/>
                    : null
            }
            <br/>
            {
                data.totalPages > 1 ?
                    <div className="flex justify-between items-center mt-4">
                        <button onClick={handlePreviousPage} disabled={currentPage <= 0}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                style={{backgroundColor: '#34495e'}}>
                            {t('pagination.previous')}
                        </button>
                        <span>{t('pagination.page')} {currentPage + 1} {t('pagination.of')} {data.totalPages}</span>
                        <button onClick={handleNextPage} disabled={currentPage >= data.totalPages - 1}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                style={{backgroundColor: '#34495e'}}>
                            {t('pagination.next')}
                        </button>
                    </div> : null
            }
            <br/>
            <br/>
            <NewProductModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
        </div>
    )
}

export default Product;
