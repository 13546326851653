import axios from 'axios';

class BaseService {
    constructor(baseURL, additionalConfig = {}) {
        this.axiosInstance = axios.create({
            baseURL: baseURL,
            ...additionalConfig, // Allows additional Axios configurations
        });
    }

    get(url, params , config) {
        return this.axiosInstance.get(url, { ...config, params: params});
    }

    post(url, data, config ) {
        return this.axiosInstance.post(url, data, config);
    }

    put(url, data, config) {
        return this.axiosInstance.put(url, data, config);
    }

    patch(url, data, config) {
        return this.axiosInstance.patch(url, data, config);
    }

    delete(url, config) {
        return this.axiosInstance.delete(url, config);
    }
}

export default BaseService;
