import React from 'react';
import { Navigate, Outlet,useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = () => {
    const {token ,isAuthenticated, isInitializing} = useSelector((state) => state.auth);
    const location = useLocation();

    if (isInitializing) {
        return <div>Loading authentication status...</div>; // or your loading spinner
    }

    if(!token && !isAuthenticated){
        return <Navigate to={{ pathname: '/login', state: { from: location } }} replace />;
    }
    return <Outlet/>;
};

export default ProtectedRoute;
