import { takeEvery, put, call } from 'redux-saga/effects';
import {searchCustomers} from '../../../services/customer/customer-service'
import {receiveData, SEARCH_CUSTOMERS_REQUEST_DATA, requestFailed} from "../../actions/customer/search-customers-actions";

function* fetchData(action) {
    try {
        const response = yield call(
            searchCustomers, // Function reference
            action.payload['params']
        );
        yield put(receiveData(response));
    } catch (error) {
        yield put(requestFailed(error.toString()));
    }
}

export function* watchSearchForCustomers() {
    yield takeEvery(SEARCH_CUSTOMERS_REQUEST_DATA, fetchData);
}
