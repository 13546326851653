import { combineReducers } from 'redux';
import currencyDataReducer from './currency/fetch-currency-data-reducer';
import authReducer from './auth-reducer';
import searchCustomersReducer from './customer/search-customers-data-reducer'
import createCustomerReducer from "./customer/create-customer-reducer";
import searchProductsReducer from "./product/search-product-data-reducer";
import createProductReducer from "./product/create-product-reducer";

const rootReducer = combineReducers({
    searchCustomersData: searchCustomersReducer,
    currencyData: currencyDataReducer,
    auth: authReducer,
    createCustomer: createCustomerReducer,
    searchProductsData: searchProductsReducer,
    createProduct: createProductReducer
});

export default rootReducer;
