import { takeEvery, put, call } from 'redux-saga/effects';
import {searchProducts} from '../../../services/product/product-service'
import {receiveData, SEARCH_PRODUCT_REQUEST_DATA, requestFailed} from "../../actions/product/search-product-actions";

function* fetchData(action) {
    try {
        const response = yield call(
            searchProducts, // Function reference
            action.payload['params']
        );
        yield put(receiveData(response));
    } catch (error) {
        yield put(requestFailed(error.toString()));
    }
}

export function* watchSearchForProducts() {
    yield takeEvery(SEARCH_PRODUCT_REQUEST_DATA, fetchData);
}
