import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import CurrencyTable from '../../components/CurrencyTable'
import './Dashboard.scss'

const Dashboard = () => {

    const dispatch = useDispatch()

    const {data} = useSelector((state) => state.currencyData);

    const [currencyClient, setCurrencyClient] = useState('RAIFFEISEN')


    function handleCurrencyRadioButtonClick(e) {
        let client = e.target.value;
        setCurrencyClient(client)
    }

    useEffect(
        () => {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth() + 1; // getMonth() returns 0-11, so add 1 for 1-12
            const day = currentDate.getDate();
            // Format the date as a string (e.g., "YYYY-MM-DD")
            const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

            dispatch({type: 'REQUEST_CURRENCY_DATA', payload: {date: formattedDate}})
        }
        , [])

    return (
        <section className="dashboard-section">
            <div className="container mx-auto customers-container">
                <div className="flex gap-10">
                    <div className="inline-flex items-center">
                        <input type="radio"
                               id="raiffeisen-option"
                               name="currency-options"
                               className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-gray-900 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                               value="RAIFFEISEN"
                               onChange={handleCurrencyRadioButtonClick}
                               checked={currencyClient === 'RAIFFEISEN'}/>
                        <label htmlFor="raiffeisen-option"
                               className="relative flex items-center p-3 rounded-full cursor-pointer">Raiffeisen
                        </label>
                    </div>
                    <div className="inline-flex items-center">
                        <input type="radio"
                               className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-gray-900 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
                               id="banca-intesa-option"
                               name="currency-options"
                               value="BANCA_INTESA"
                               onChange={handleCurrencyRadioButtonClick}
                               checked={currencyClient === 'BANCA_INTESA'}
                        />
                        <label htmlFor="banca-intesa-option" className="relative flex items-center p-3 rounded-full cursor-pointer">Banca Intesa</label>
                    </div>
                </div>
                <div className="flex-container-center">
                    <CurrencyTable data={data} client={currencyClient}/>
                </div>
            </div>
        </section>
    );
};


export default Dashboard;
