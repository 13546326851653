import {
    SEARCH_CUSTOMERS_REQUEST_DATA,
    SEARCH_CUSTOMERS_RECEIVE_DATA,
    SEARCH_CUSTOMERS_REQUEST_DATA_FAILED
} from "../../actions/customer/search-customers-actions";


const initialState = {
    data: {
        value: [],
        page: 0,
        size: 0,
        totalCount: 0,
        totalPages: 0
    },
    isLoading: false,
    error: null
};

export default function searchCustomersReducer(state = initialState, action) {
    switch (action.type) {
        case SEARCH_CUSTOMERS_REQUEST_DATA:
            return { ...state, isLoading: true };
        case SEARCH_CUSTOMERS_RECEIVE_DATA:
            return {
                ...state,
                isLoading: false,
                data: {
                    value: action.payload.value || [],
                    page: action.payload.page || 0,
                    size: action.payload.size || 0,
                    totalCount: action.payload.totalCount || 0,
                    totalPages: action.payload.totalPages || 0
                }
            };
        case SEARCH_CUSTOMERS_REQUEST_DATA_FAILED:
            return { ...state, isLoading: false, error: action.payload };
        default:
            return state;
    }
}
