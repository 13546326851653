import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {submitForm, updateForm} from "../store/actions/product/create-product-actions";
import {ProductTypes, SupportedClients} from "../containers/product/Product";


function NewProductModal({isOpen, onClose}) {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    // Accessing Redux state
    const { formData, loading, error, response } = useSelector(state => state.createProduct);


    useEffect(() => {
        if (response) {
            // Handle successful form submission
            console.log(response);
            onClose();
        }

        if (error) {
            // Handle form submission error
            console.error(error);
        }
    }, [response, error, onClose]);



    const handleChange = (e) => {
        const { name, value } = e.target;
        const nameParts = name.split('.');

        // Prepare new formData for dispatching
        let newFormData = { ...formData };

        if (nameParts.length === 1) {
            newFormData = { ...newFormData, [name]: value };
        } else {
            newFormData = {
                ...newFormData,
                [nameParts[0]]: {
                    ...newFormData[nameParts[0]],
                    [nameParts[1]]: value,
                },
            };
        }

        dispatch(updateForm(newFormData));
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(submitForm(formData));
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg">{t('product.addProduct')}</h3>
                    <button onClick={onClose} className="text-gray-400 hover:bg-gray-200 p-2 rounded">
                        X
                    </button>
                </div>
                <select
                    name="type"
                    value={formData.type}
                    onChange={(e) => {
                        handleChange(e)
                    }}
                    className="mb-4 p-2 border rounded"
                >
                    <option value={ProductTypes.DEFAULT}>{t('product.default')}</option>
                    <option value={ProductTypes.VIDEO}>{t('product.video')}</option>
                    <option value={ProductTypes.AUDIO}>{t('product.audio')}</option>
                </select>

                <form onSubmit={handleSubmit}>
                    {/* Common fields */}
                    <br/>
                    <div className="space-y-2">
                        <input type="text" name="name" placeholder={t('product.name')}
                               value={formData.name} onChange={handleChange}
                               className="block w-full mb-2 p-2 border rounded"/>
                        <input type="text" name="code" placeholder={t('product.code')}
                               value={formData.code} onChange={handleChange}
                               className="block w-full mb-2 p-2 border rounded"/>
                        <input type="text" name="description" placeholder={t('product.description')}
                               value={formData.description} onChange={handleChange}
                               className="block w-full mb-2 p-2 border rounded"/>
                        <input type="number" name="price" placeholder={t('product.price')}
                               value={formData.price} onChange={handleChange}
                               className="block w-full mb-2 p-2 border rounded"/>
                        <br/>
                        <select
                            name="company"
                            value={formData.company}
                            onChange={(e) => {
                                handleChange(e)
                            }}
                            className="mb-4 p-2 border rounded"
                        >
                            <option value={SupportedClients.ARRI}>{t('product.arri')}</option>
                            <option value={SupportedClients.SONY}>{t('product.sony')}</option>
                            <option value={SupportedClients.RED}>{t('product.red')}</option>
                            <option value={SupportedClients.DEFAULT}>{t('product.default')}</option>
                        </select>
                    </div>
                    <br/>
                    <button type="submit"
                            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
                        Submit
                    </button>
                </form>
            </div>
        </div>
    )
}

export default NewProductModal;
