import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PublicRoute = () => {
    const {token,isAuthenticated} = useSelector((state) => state.auth);
    const location = useLocation();
    if(token && isAuthenticated){
        return <Navigate to={{ pathname: '/dashboard', state: { from: location } }} replace />
    }

    return <Outlet />;
};

export default PublicRoute;
