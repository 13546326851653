import {
    REQUEST_CURRENCY_DATA,
    RECEIVE_CURRENCY_DATA,
    REQUEST_CURRENCY_DATA_FAILED
} from '../../actions/currency/fetch-currency-data-actions';

const initialState = {
    data: null,
    isLoading: false,
    error: null
};

export default function currencyDataReducer(state = initialState, action) {
    switch (action.type) {
        case REQUEST_CURRENCY_DATA:
            return { ...state, isLoading: true };
        case RECEIVE_CURRENCY_DATA:
            return { ...state, isLoading: false, data: action.payload };
        case REQUEST_CURRENCY_DATA_FAILED:
            return { ...state, isLoading: false, error: action.payload };
        default:
            return state;
    }
}
